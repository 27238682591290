/* global $ */
import _ from 'lodash';

$(document).ready(function() {
  $('.get-togethers-slider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 30000,
    dots: true,
    arrows: true,
    centerMode: true,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  });

  const updateSlidesHeight = (slider) => {
    const heights = [];
    $(slider).height('auto');
    $(slider).each((_id, element) => {
      heights.push(element.getBoundingClientRect().height);
    });
    $(slider).height(Math.max(...heights));
  }

  updateSlidesHeight('.get-togethers-slider .slick-slide');
  $(window).on('resize', _.debounce(() => updateSlidesHeight('.get-togethers-slider .slick-slide'), 150));

  $('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    asNavFor: '.slider-nav',
    appendArrows: $('.sliders .controls')
  });

  $('.slider-nav').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    arrows: false,
    dots: true,
    focusOnSelect: true,
    appendDots: $('.your-story')
  });

  if (document.querySelector('.business')) return;
  const sections = $('header, article');
  const nav = $('.aside-nav ul');

  function scroller() {
    const cur_pos = $(this).scrollTop();

    sections.each(function () {
      const top = $(this).offset().top - (0.25 * innerHeight),
        bottom = top + $(this).outerHeight();

      if (cur_pos >= top && cur_pos <= bottom) {
        nav.find('li a').removeClass('active');
        nav.find('li a[href="#' + $(this).attr('id') + '"]').addClass('active');
      } 
    }); 
  }

  $(window).on('scroll', scroller);
  $(window).on('touchmove', scroller);

  $('[href^="#"]').on('click', event => {
    event.preventDefault();
    const id = event.target.hash;

    let offset = 0;
    document.querySelectorAll('.top-bar > *').forEach((n) => { offset += n.getBoundingClientRect().height });
    const rightMenu = document.getElementById('right-menu');
    if (rightMenu) rightMenu.classList.remove('active');
        
    $('html, body').animate({
        scrollTop: $(id).offset().top - offset + 10
    }, 1000);
  });
});

const faqs = document.querySelectorAll('.faq-header');

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || 
                              Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    let el = this;

    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

function handleTogglefaq(event) {
  const parent = event.target.closest('.faq-box');
  const header = parent.querySelector('.faq-header')
  const body = parent.querySelector('.faq-body');
  const icon = parent.querySelector('.faq-toggle');
  const bodyChildren = body.children;
  const bodyChildrenCount = body.childElementCount;
  let contentHeight = 0;

  
  if (header.classList.contains('disabled')) {
    return;
  }

  for(let i = 0; i < bodyChildrenCount; i++) {
    contentHeight += bodyChildren[0].getClientRects()[0].height + 24;
  }

  if (parent.classList.contains('is-open')) {
    togglefaq(body, contentHeight, 0, 375);
    parent.classList.remove('is-open');
    icon.classList.remove('toggled');
  } else {
    togglefaq(body, 0, contentHeight, 375);
    parent.classList.add('is-open');
    icon.classList.add('toggled');
  }
}

function togglefaq(el, start = 0, end = 0, timing = 200) {
  const target = start < end ? end : start; 
  const open = start > end ? true : false
  const frames = Math.round(timing / 16.6);
  const step = Math.ceil(target / frames);
  let framesDone = 0;
  let progress = open ? target : 0;

  el.style.maxHeight = progress;

  function animatefaq () {
    el.style.maxHeight = `${progress}px`;
    framesDone += 1;

    if (open && progress <= target) {
      progress -= step;
    } else {
      progress += step;
    }
    if (framesDone === frames) {
      el.style.maxHeight = `${open ? 0 : target}px`;
    }
    
    if (framesDone < frames) {
      window.requestAnimationFrame(animatefaq);
    }
  }
  animatefaq();
}

for(let i = 0; i < faqs.length; i++) {
  faqs[i].addEventListener('click', handleTogglefaq, true);
}
